import { validatorRequire } from '@/utils/validators';
import DSESelect from '@/components/DSE/DSESelect';
import TextArea from '@/components/DSE/TextArea';
import { computed } from 'vue';

export function getFormDefinition(vue) {
  return {
    whenLoanUnderAgreementLimit: {
      type: DSESelect,
      defaultValue: null,
      props: {
        caption: 'LoanStatusChangeTrigger.whenUnderLoanAgreementLimitQuestion',
        valueField: 'value',
        labelField: 'name',
        options: [
          {
            value: null,
            name: vue.$t('LoanStatusChangeTrigger.Always')
          },
          {
            value: false,
            name: vue.$t('LoanStatusChangeTrigger.AboveAgreementLimit')
          },
          {
            value: true,
            name: vue.$t('LoanStatusChangeTrigger.UnderAgreementLimit')
          }
        ]
      }
    },
    onAutomaticChange: {
      type: DSESelect,
      defaultValue: null,
      props: {
        caption: 'LoanStatusChangeTrigger.onAutomaticChangeQuestion',
        valueField: 'value',
        labelField: 'name',
        options: [
          {
            value: null,
            name: vue.$t('LoanStatusChangeTrigger.Always')
          },
          {
            value: false,
            name: vue.$t('LoanStatusChangeTrigger.ByManualChange')
          },
          {
            value: true,
            name: vue.$t('LoanStatusChangeTrigger.BySystemChange')
          }
        ]
      }
    },
    loanTypeId: {
      type: DSESelect,
      defaultValue: null,
      props: {
        caption: 'LoanStatusChangeTrigger.loanType',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['loanType/isLoading'],
        options: [
          {
            id: null,
            name: vue.$t('LoanStatusChangeTrigger.all')
          },
          ...vue.$store.getters['loanType/loadedItems']
        ]
      }
    },
    actionTypeIds: {
      type: DSESelect,
      props: {
        caption: 'LoanStatusChangeTrigger.actionTypes',
        multiple: true,
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['actionType/isLoading'],
        options: vue.$store.getters['actionType/loadedItems']
      },
      rules: [{ validator: validatorRequire }]
    },
    statusId: {
      type: DSESelect,
      props: {
        caption: 'common.status',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['status/isLoading'],
        options: vue.$store.getters['status/loadedItems']
      },
      rules: [{ validator: validatorRequire }]
    },
    description: {
      type: TextArea
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
